import { useEffect, useState } from 'react';

export const useTableSortItem = (tableName) => {
  const [sortItem, setSortItem] = useState();

  const saveTableFilter = () =>
    localStorage.setItem(tableName, JSON.stringify(sortItem));

  useEffect(() => {
    const tableFilter = localStorage.getItem(tableName);
    const tableFilterParsed = tableFilter ? JSON.parse(tableFilter) : undefined;

    if (tableFilterParsed) {
      setSortItem(tableFilterParsed);
    }
  }, []);

  useEffect(() => {
    if (sortItem) {
      saveTableFilter();
    }
  }, [sortItem]);

  return { sortItem, setSortItem }
};
