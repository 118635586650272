import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import Header from "./Header";
import LeftMenu from "./LeftMenu";
import { getUser } from "../store/auth/auth.selectors";
import { actions } from "../store/auth/auth.reducers";
import { actions as toastActions } from "../store/toast/toast.reducers";
import axios from "../axios";
import { useWebSocket } from "../hooks/useWebSocket";

// import TrialEnded from "./common/TrialEnded";

const Layout = ({ children, pathname }) => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const userData = useSelector(getUser);
  const userSavedData = localStorage.getItem("user");

  const isMobile = useMediaQuery("(max-width:990px)");
  const isCollapsedFromStorage = localStorage.getItem("isMenuCollapsed");
  const isProcessPayrollStarted = localStorage.getItem("isProcessPayrollStarted");
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    if (!isMobile && isCollapsedFromStorage) {
      const isCollapsedFromStorageToBool = isCollapsedFromStorage === "false" ? false : true;
      setIsCollapsed(isCollapsedFromStorageToBool);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      axios
        .get("/auth/user")
        .then((response) => {
          dispatch(actions.loginUser(response));
          localStorage.setItem("user", JSON.stringify(response));
          if (response?.companies_count === 0) {
            nav("/onboard-company");
            return;
          }
        })
        .catch((err) =>
          dispatch(
            toastActions.toast({
              code: "error",
              message: err.response?.data?.message || err.message
            })
          )
        );
    } else if (Object.keys(JSON.parse(userSavedData || "{}")).length !== 0) {
      dispatch(actions.loginUser(JSON.parse(userSavedData)));
    } else if (userData.companies_count === 0) {
      nav("/onboard-company");
      return;
    }
  }, []);

  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      window.Intercom("boot", {
        app_id: "dyklmyeq",
        name: userData.first_name,
        email: userData.email,
        user_id: userData.id,
        user_hash: userData.hmac
      });
    }
  }, [userData]);

  const { echo } = useWebSocket(userData, JSON.parse(isProcessPayrollStarted));

  const isSubscribedKeyExisting = Object.keys(userData).includes("is_subscribed");
  const isCompanySubscribed = userData.is_subscribed;
  const trialDaysDiff = Math.ceil(dayjs(userData.subscription_ends_at).diff(dayjs(), "hours") / 24);
  const isShowTrial = !isCompanySubscribed && trialDaysDiff <= 5;
  const isTrialEnded = userData.is_trial_expired || trialDaysDiff < 1;
  const isPastDue = userData.is_past_due;
  const paymentUrl = userData.payment_url;

  const handleAddInfo = () => nav("/settings?page=subscription&add_info_trial=true");
  const handleCheckInfo = () => nav("/settings?page=subscription");

  useEffect(() => {
    if (userData && userData.companies_count === 0) {
      nav("/onboard-company");
    }
  }, [pathname, userData]);

  let message

  if (isPastDue) {
    if (paymentUrl) {
      message = (
          <div className='trial-wrapper'>
            Your subscription is paused. Please, check your payment details, or pay
            <a href={paymentUrl} target="_blank" className='check-payment-button'>
              here
            </a>.
          </div>
      );
    } else {
      message = (
          <div className='trial-wrapper'>
            Your subscription is paused. Please, check your payment details or try again later.
          </div>
      );
    }
  } else if (isShowTrial && isSubscribedKeyExisting) {
    message = (
      <div className='trial-wrapper'>
        {isTrialEnded ? (
          'The trial period is ended'
        ) : (
          <>
            The trial period is ending in <b>{trialDaysDiff} days</b>.
          </>
        )}{' '}
        Please provide your information to continue using the service!{' '}
        <div className='trial-button' onClick={handleAddInfo}>
          Add information
        </div>
      </div>
    );
  }

  return (
    <div className="main-outer-sec">
      {message}
      <LeftMenu setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
      <div
        className="content-sec scrollbar"
        style={{
          width:
            isCollapsed && !isMobile
              ? "calc(100% - 100px)"
              : isMobile
                ? "100%"
                : "calc(100% - 290px)"
        }}>
        <Header />
        {/* {!isCompanySubscribed && isTrialEnded && isWageCalculatorPage && isSubscribedKeyExisting ? (
          <TrialEnded onClick={handleAddInfo} />
        ) : ( */}
        {React.cloneElement(children, { echo: echo })}
        {/* )} */}
      </div>
    </div>
  );
};

export default memo(Layout);
