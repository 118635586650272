import { useSelector } from "react-redux";
import { getLeaderboardList } from "../../store/leaderboard/leaderboard.selectors";
import LeaderboardTableRaw from "./LeaderboardTableRaw";
import TableSortArrowsBlock from "../common/TableSortArrowsBlock";

const TopEmployeesTable = ({ onlyFirstPage, handleSortChange, activeSortedItem }) => {
  const leaderboardList = useSelector(getLeaderboardList);

  return (
    <div className="leaderboard-top-table">
      <table>
        <thead>
          <tr>
            <th>
              <div className="leaderboard-top-table-header-item">
                Ranked{" "}
                <TableSortArrowsBlock
                  handleSortChange={handleSortChange}
                  noSort={onlyFirstPage}
                  itemName="position"
                  activeSortedItem={activeSortedItem}
                />
              </div>
            </th>
            <th>
              <div className="leaderboard-top-table-header-item">
                Name{" "}
                <TableSortArrowsBlock
                  handleSortChange={handleSortChange}
                  noSort={onlyFirstPage}
                  itemName="first_name"
                  activeSortedItem={activeSortedItem}
                />
              </div>
            </th>
            <th>
              <div className="leaderboard-top-table-header-item">
                Stops Per Day{" "}
                <TableSortArrowsBlock
                  handleSortChange={handleSortChange}
                  noSort={onlyFirstPage}
                  itemName="stops_per_day"
                  activeSortedItem={activeSortedItem}
                />
              </div>
            </th>
            <th>
              <div className="leaderboard-top-table-header-item">
                Packages Per Day{" "}
                <TableSortArrowsBlock
                  handleSortChange={handleSortChange}
                  noSort={onlyFirstPage}
                  itemName="packages_per_day"
                  activeSortedItem={activeSortedItem}
                />
              </div>
            </th>
            <th>
              <div className="leaderboard-top-table-header-item">
                Number of Stops{" "}
                <TableSortArrowsBlock
                  handleSortChange={handleSortChange}
                  noSort={onlyFirstPage}
                  itemName="number_of_stops"
                  activeSortedItem={activeSortedItem}
                />
              </div>
            </th>
            <th>
              <div className="leaderboard-top-table-header-item">
                Gross Profit{" "}
                <TableSortArrowsBlock
                  handleSortChange={handleSortChange}
                  noSort={onlyFirstPage}
                  itemName="profit"
                  activeSortedItem={activeSortedItem}
                />
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          {onlyFirstPage &&
            leaderboardList
              .slice(0, 10)
              .map((item) => <LeaderboardTableRaw key={item.employee.id} userData={item} />)}
          {!onlyFirstPage &&
            leaderboardList.map((item) => (
              <LeaderboardTableRaw key={item.employee.id} userData={item} />
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default TopEmployeesTable;
