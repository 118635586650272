import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import axios from "../../axios";
import { actions as toastActions } from "../../store/toast/toast.reducers";
import Loading from "./../common/Loading";
import TitleInfo from "../common/TitleInfo";
import SettingsFixedExpensesContent from "./expenses/SettingsFixedExpensesContent";
import { ReactComponent as Taxes } from "../../assets/images/settings/taxes.svg";
import { ReactComponent as Compensation } from "../../assets/images/settings/compensation.svg";
import { ReactComponent as UcEr } from "../../assets/images/settings/uc-er.svg";
import { ReactComponent as Health } from "../../assets/images/settings/health.svg";
import { ReactComponent as Avant } from "../../assets/images/settings/avant.svg";
import { ReactComponent as Elpi } from "../../assets/images/settings/elpi.svg";
import { ReactComponent as Scanners } from "../../assets/images/settings/scanners.svg";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";
import AddCustomExpensesModal from "../modals/AddCustomExpensesModal";

const SettingsFixedExpenses = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [settingsData, setSettingsData] = useState([]);
  const [isUpdateNeed, setIsUpdateNeed] = useState(false);

  const [addOpen, setAddOpen] = useState(false);
  const [isAddLoading, setIsAddLoading] = useState(false);
  const [editedExpenses, setEditedExpenses] = useState();

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [deletedExpenses, setDeletedExpenses] = useState();

  useEffect(() => {
    if (!isUpdateNeed) {
      fetchCompanies();
    }

    if (isUpdateNeed) {
      setIsUpdateNeed(false);
    }
  }, [isUpdateNeed]);

  const fetchCompanies = () => {
    setLoading(true);

    axios
      .get("/fixed-expenses-settings")
      .then((response) => {
        if (response.success) {
          const preparedData = [
            {
              name: "Payroll taxes",
              key: "payroll_taxes",
              value: response.data.payroll_taxes,
              icon: <Taxes />,
              isPercentage: true
            },
            {
              name: "Worker Compensation",
              key: "worker_compensation",
              value: response.data.worker_compensation,
              icon: <Compensation />,
              isPercentage: true
            },
            {
              name: "Payroll UC ER",
              key: "payroll_uc_er",
              value: response.data.payroll_uc_er,
              icon: <UcEr />
            },
            {
              name: "Health & Life insurance",
              key: "health_life_insurance",
              value: response.data.health_life_insurance,
              icon: <Health />
            },
            {
              name: "Avant Insurance",
              key: "avant_insurance",
              value: response.data.avant_insurance,
              isPerEmployee: true,
              icon: <Avant />
            },
            {
              name: "ELPI Insurance",
              key: "elpi_insurance",
              value: response.data.elpi_insurance,
              isPerEmployee: true,
              icon: <Elpi />
            },
            {
              name: "Scanners driver",
              key: "scanners_driver",
              value: response.data.scanners_driver,
              isPerEmployee: true,
              icon: <Scanners />
            },
            ...response.data.custom_fields
          ];
          setSettingsData(preparedData);
        }
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      )
      .finally(() => setLoading(false));
  };

  const handleChangeItem = (itemKey, value) => {
    const items = settingsData.map((el) => (el.key === itemKey ? { ...el, value: value } : el));
    setSettingsData(items);
  };

  const handleAddOpen = () => setAddOpen(true);

  const handleEditCustomOpen = (item) => setEditedExpenses(item);

  const handleAddClose = () => {
    setAddOpen(false);
    editedExpenses && setEditedExpenses();
  };

  const handleAddConfirm = (values, handleCurClose) => {
    setIsAddLoading(true);

    if (editedExpenses) {
      axios
        .put(`/fixed-expenses-settings-custom-fields/${editedExpenses.id}`, values)
        .then((response) => {
          if (response.success) {
            const expensesListWithChange = settingsData.map((el) =>
              el.id === editedExpenses.id
                ? { ...el, cost: values.cost, title: values.title, type: values.type }
                : el
            );
            setSettingsData(expensesListWithChange);
            handleCurClose();
          }
        })
        .catch((err) =>
          dispatch(
            toastActions.toast({
              code: "error",
              message: err.response?.data?.message || err.message
            })
          )
        )
        .finally(() => setIsAddLoading(false));
    } else {
      axios
        .post("/fixed-expenses-settings-custom-fields", values)
        .then((response) => {
          if (response.success) {
            setIsUpdateNeed(true);
            handleCurClose();
          }
        })
        .catch((err) =>
          dispatch(
            toastActions.toast({
              code: "error",
              message: err.response?.data?.message || err.message
            })
          )
        )
        .finally(() => setIsAddLoading(false));
    }
  };

  const handleDeleteConfirm = () => {
    setIsDeleteLoading(true);

    axios
      .delete(`/fixed-expenses-settings-custom-fields/${deletedExpenses.id}`)
      .then((response) => {
        if (response.success) {
          setSettingsData(settingsData.filter((el) => !el.id || el.id !== deletedExpenses.id));
          handleCloseDeleteModal();
        }
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      )
      .finally(() => setIsDeleteLoading(false));
  };

  const handleOpenDeleteModal = (item, e) => {
    e.preventDefault();

    setDeletedExpenses(item);
  };

  const handleCloseDeleteModal = () => setDeletedExpenses();

  return (
    <div className="profile-companies expenses-settings">
      <div className="expenses-settings-header">
        <TitleInfo
          title="Fixed Expenses"
          subTitle="Effortlessly compute your weekly fixed expenses by entering the required information."
          buttons={[
            {
              name: "+ Add Fixed Expense",
              class: "btn-style add-employee-btn",
              onClick: handleAddOpen
            }
          ]}
        />
      </div>

      {loading ? (
        <Loading />
      ) : (
        <SettingsFixedExpensesContent
          settingsData={settingsData}
          handleChangeItem={handleChangeItem}
          handleDelete={handleOpenDeleteModal}
          handleEditCustomOpen={handleEditCustomOpen}
        />
      )}

      <ConfirmDeleteModal
        open={!!deletedExpenses}
        handleClose={handleCloseDeleteModal}
        handleConfirm={handleDeleteConfirm}
        text="Are you sure delete this Fixed Expenses?"
        isLoading={isDeleteLoading}
      />

      <AddCustomExpensesModal
        open={addOpen || !!editedExpenses}
        editedExpenses={editedExpenses}
        handleClose={handleAddClose}
        handleConfirm={handleAddConfirm}
        isLoading={isAddLoading}
      />
    </div>
  );
};

export default SettingsFixedExpenses;
