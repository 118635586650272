import axios from 'axios';
import { actions as toastActions } from './store/toast/toast.reducers';
import store from './store/store';

const UNAUTHORIZED = 401;
const SERVICEUNAVAILABLE = 503;

export const setInstanceAuth = (instance) => {
  instance.interceptors.request.use(function (config) {
    config.headers = config.headers ?? {};

    let token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    let impersonateUserToken = localStorage.getItem('impersonateUserToken');
    config.headers.Impersonated = Boolean(impersonateUserToken);

    return config;
  });
};

const config = {
  baseURL: process.env.REACT_APP_API_HOST
};
const instance = axios.create(config);

instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // Logout
    if (error.response?.status === UNAUTHORIZED && window.location.pathname !== '/login') {
      localStorage.removeItem('token');
      window.Intercom('shutdown');
      window.location.pathname = '/login'
    }

    if (error.response?.status === SERVICEUNAVAILABLE) {
      if (window.location.pathname !== '/503') {
        localStorage.setItem('prevPage', window.location.pathname);
        window.location.pathname = '/503';
      }

      return;
    }

    let message = 'Something went wrong';

    if (error.response?.data?.message) {
      message = error.response.data.message;
    }

    store.dispatch(toastActions.toast({ code: 'error', message: message }));

    return Promise.reject(error);
  }
);
setInstanceAuth(instance);

export default instance;
