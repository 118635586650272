import { Delete } from "@mui/icons-material";

const CouponsTableRaw = ({ couponData, handleDelete }) => {
  return (
    <tr>
      <td>{couponData.name}</td>
      <td>{couponData.id}</td>
      <td>{couponData.percent_off}</td>
      <td>{couponData.amount_off}</td>
      <td>{couponData.duration}</td>
      <td>{couponData.duration_in_months}</td>

      <td>
        <button className="companies-btn delete" onClick={handleDelete.bind(null, couponData)}>
          <Delete className="companies-delete" />
        </button>
      </td>
    </tr>
  );
};

export default CouponsTableRaw;
