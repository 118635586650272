import { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CacheProvider } from "@emotion/react";
import { getUser } from "../store/auth/auth.selectors";
import { actions } from "../store/auth/auth.reducers";
import { actions as toastActions } from "../store/toast/toast.reducers";
import axios from "../axios";
import { AdminLayout } from "./AdminLayout";
import { createEmotionCache } from "./utils/create-emotion-cache";
import { createTheme } from "./theme";

const clientSideEmotionCache = createEmotionCache();

const AdminWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const userData = useSelector(getUser);

  const theme = createTheme();

  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      axios
        .get("/auth/user")
        .then((response) => {
          dispatch(actions.loginUser(response));
          localStorage.setItem("user", JSON.stringify(response));
        })
        .catch((err) =>
          dispatch(
            toastActions.toast({
              code: "error",
              message: err.response?.data?.message || err.message
            })
          )
        );
    }
  }, []);

  useEffect(() => {
    window.Intercom("shutdown");
  }, []);

  return (
    <CacheProvider value={clientSideEmotionCache}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AdminLayout>{children}</AdminLayout>
        </ThemeProvider>
      </LocalizationProvider>
    </CacheProvider>
  );
};

export default memo(AdminWrapper);
