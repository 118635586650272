import { memo, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useDispatch } from "react-redux";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import { calculateTotalPayAndPerStop, formatMoney, formatNumber } from "../../utils";
import { useDebouncedEffect } from "../../hooks/useDebouncedEffect";
import { actions } from "../../store/payroll-preview/payroll-preview.reducers";
import axios from "../../axios";
import { actions as toastActions } from "../../store/toast/toast.reducers";

const RunPayrollStatementMoreTableRow = ({ itemData, employee }) => {
  const dispatch = useDispatch();

  const {
    statement,
    station,
    total_w2,
    total_fuel,
    total_stops,
    total_packages,
    working_days,
    profit_before_changes,
    pto,
    total_hours,
    addition = 0,
    deduction,
    profit,
    training_days,
    is_hours_disabled,
    total_wa_hourly
  } = itemData;

  const rates = employee?.rates || {};

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [bonus, setBonus] = useState(+addition ? addition : "0.00");
  const [deductionBonus, setDeductionBonus] = useState(+deduction ? deduction : "0.00");
  const [newPto, setNewPto] = useState(pto);
  const [newTrainingDays, setNewTrainingDays] = useState(training_days);
  const [totalHours, setTotalHours] = useState(total_hours);
  const [totalFuel, setTotalFuel] = useState(total_fuel);
  const [totalPayroll, setTotalPayroll] = useState(0);
  const [perStop, setPerStop] = useState(0);
  const [curProfit, setCurProfit] = useState(profit);

  const isNotNativeCompany = station.csa !== employee.station?.csa;

  useEffect(() => {
    if (!isFirstRender) {
      dispatch(actions.addAlertToExpenses());
    }
  }, [bonus, newPto, totalHours, deductionBonus]);

  useEffect(() => {
    const { elTotalPay, perStop } = calculateTotalPayAndPerStop(
      itemData,
      station.csa,
      employee,
      totalFuel
    );

    setTotalPayroll(elTotalPay);
    setPerStop(perStop);
  }, []);

  useDebouncedEffect(
    () => {
      if (isFirstRender) {
        setIsFirstRender(false);
        return;
      }

      const { elTotalPay: newTotalPayroll, perStop: newPerStop } = calculateTotalPayAndPerStop(
        {
          addition: +bonus || 0,
          deduction: +deductionBonus || 0,
          pto: newPto || 0,
          employee: employee,
          total_hours: +totalHours || 0,
          total_w2: total_w2 || 0,
          total_stops: +total_stops || 0,
          total_fuel: +totalFuel || 0,
          training_days: newTrainingDays || 0,
          is_hours_disabled,
          total_wa_hourly: total_wa_hourly
        },
        station.csa
      );

      setTotalPayroll(newTotalPayroll);
      setPerStop(newPerStop);
      setCurProfit(profit_before_changes - totalFuel - (newTotalPayroll - total_w2));

      const body = {
        deduction: deductionBonus,
        addition: bonus,
        pto: newPto,
        total_hours: totalHours,
        total_fuel: totalFuel,
        training_days: newTrainingDays
      };

      axios
        .put(`/statement/${statement.id}/weekly/${itemData.id}`, body)
        .catch(() =>
          dispatch(toastActions.toast({ code: "error", message: "Something went wrong" }))
        );
    },
    [bonus, newPto, totalHours, totalFuel, deductionBonus, newTrainingDays],
    500
  );

  const handleBonusChange = (v) => setBonus(!v || v !== "0.00" ? v?.toFixed(2) : "0.00");

  const handleDeductionBonusChange = (v) =>
    setDeductionBonus(!v || v !== "0.00" ? v?.toFixed(2) : "0.00");

  const handleIncrease = () => {
    if (!rates?.paid_time_off || isNotNativeCompany) {
      return;
    }
    if (rates?.paid_time_off && newPto < 5 && working_days + newTrainingDays + newPto < 7) {
      setNewPto(newPto + 1);
    } else {
      dispatch(
        toastActions.toast({ code: "error", message: "Employee can’t work more than 7 days/week" })
      );
    }
  };

  const handleDecrease = () => {
    if (!rates?.paid_time_off || isNotNativeCompany) {
      return;
    }
    if (rates?.paid_time_off && newPto > 0) {
      setNewPto(newPto - 1);
    }
  };

  const handleTrainingDaysIncrease = () => {
    if (working_days + newTrainingDays + newPto < 7) {
      setNewTrainingDays(newTrainingDays + 1);
    } else {
      dispatch(
        toastActions.toast({ code: "error", message: "Employee can’t work more than 7 days/week" })
      );
    }
  };

  const handleTrainingDaysDecrease = () => {
    if (newTrainingDays > 0) {
      setNewTrainingDays(newTrainingDays - 1);
    }
  };

  const handleTotalHoursChange = (v) => setTotalHours(v);
  const handleTotalFuelChange = (v) => setTotalFuel(v?.toFixed(2) || "0.00");
  const stationMinimumWage = station.minimum_wage || 7.25;
  const isErrorTotalPay =
    (totalHours > 0 && totalHours * stationMinimumWage > totalPayroll) ||
    (working_days > 0 && totalPayroll === 0);

  const curPayValue =
    total_w2 +
    (rates.is_work_area_enabled === 0 ? totalHours * +rates.hourly_rate : total_wa_hourly);

  return (
    <tr
      style={{
        background: ![1, "1", true, "true"].includes(employee.active)
          ? "#F5F5F6"
          : isErrorTotalPay
            ? "#FFE8EA"
            : "#FFFFFF",
        borderLeft: "1px solid #EEF0F2",
        borderRight: "1px solid #EEF0F2"
      }}>
      <td className="no-hover-effect" style={{ borderBottom: "none" }}>
        <div className="statement-first-block">
          <div className="statement-name-block" style={{ marginLeft: "54px" }}>
            <div className="statement-name-block-content">
              <div>{station.company.name}</div>
            </div>

            <div className="statement-company">{station.name}</div>
          </div>
        </div>
      </td>
      <td className="no-hover-effect" style={{ borderBottom: "none" }}>
        {working_days + newTrainingDays + newPto}
      </td>
      <td className="no-hover-effect" style={{ borderBottom: "none" }}>
        {formatMoney(perStop, true)}
      </td>
      <td className="no-hover-effect" style={{ borderBottom: "none" }}>
        <div className="form-item statement-input fuel">
          <div className="input-box fuel">
            <NumericFormat
              value={totalFuel}
              onValueChange={(values) => {
                handleTotalFuelChange(values.floatValue || 0);
              }}
              allowEmptyFormatting
              prefix="$"
              thousandSeparator
              allowNegative={false}
              isAllowed={(values) => {
                const { floatValue } = values;
                return floatValue < 10000 || [0, null, undefined].includes(floatValue);
              }}
            />
          </div>
        </div>
      </td>
      <td className="no-hover-effect" style={{ borderBottom: "none" }}>
        {formatNumber(total_stops)}
      </td>
      <td className="no-hover-effect" style={{ borderBottom: "none" }}>
        {formatNumber(total_packages)}
      </td>
      <td className="no-hover-effect" style={{ borderBottom: "none" }}>
        {formatMoney(curPayValue, true)}
      </td>
      <td className="no-hover-effect" style={{ borderBottom: "none" }}>
        <div className="form-item statement-input">
          <div className="input-box">
            <NumericFormat
              value={!bonus || bonus === 0 ? "0.00" : bonus}
              onValueChange={(values) => {
                handleBonusChange(values.floatValue || "0.00");
              }}
              allowEmptyFormatting
              prefix="$"
              thousandSeparator
              allowNegative={false}
              isAllowed={(values) => {
                const { floatValue } = values;
                return floatValue < 10000 || [0, null, undefined].includes(floatValue);
              }}
            />
          </div>
        </div>
      </td>
      <td className="no-hover-effect" style={{ borderBottom: "none" }}>
        <div className="form-item statement-input">
          <div className="input-box">
            <NumericFormat
              value={!deductionBonus || deductionBonus === 0 ? "0.00" : deductionBonus}
              onValueChange={(values) => {
                handleDeductionBonusChange(values.floatValue || "0.00");
              }}
              allowEmptyFormatting
              prefix="$"
              thousandSeparator
              allowNegative={false}
              isAllowed={(values) => {
                const { floatValue } = values;
                return floatValue < 10000 || [0, null, undefined].includes(floatValue);
              }}
            />
          </div>
        </div>
      </td>
      <td className="no-hover-effect" style={{ borderBottom: "none" }}>
        <div className="form-item statement-input number">
          <div className="input-box">
            <NumericFormat
              value={newTrainingDays}
              type="number"
              allowNegative={false}
              isAllowed={(values) => {
                const { floatValue } = values;
                return floatValue >= 0 && floatValue <= 7;
              }}
            />
            <div className="statement-input-btns">
              <KeyboardArrowUp fontSize="small" onClick={handleTrainingDaysIncrease} />
              <KeyboardArrowDown fontSize="small" onClick={handleTrainingDaysDecrease} />
            </div>
          </div>
        </div>
      </td>
      <td className="no-hover-effect" style={{ borderBottom: "none" }}>
        <div className="form-item statement-input number">
          <div className="input-box">
            <NumericFormat
              value={newPto}
              type="number"
              disabled={!rates?.paid_time_off || isNotNativeCompany}
              allowNegative={false}
              isAllowed={(values) => {
                const { floatValue } = values;
                return floatValue >= 0 && floatValue <= 5;
              }}
            />
            {!isNotNativeCompany && (
              <div className="statement-input-btns">
                <KeyboardArrowUp fontSize="small" onClick={handleIncrease} />
                <KeyboardArrowDown fontSize="small" onClick={handleDecrease} />
              </div>
            )}
          </div>
        </div>
      </td>
      <td className="no-hover-effect" style={{ borderBottom: "none" }}>
        <div className="form-item statement-input hours">
          <div className="input-box">
            <NumericFormat
              value={totalHours}
              disabled={is_hours_disabled === 1}
              decimalScale={2}
              fixedDecimalScale
              onValueChange={(values) => {
                handleTotalHoursChange(values.floatValue || 0);
              }}
              allowEmptyFormatting
              allowNegative={false}
              isAllowed={(values) => {
                const { floatValue } = values;
                return (
                  (floatValue >= 0 && floatValue <= 168) ||
                  [0, null, undefined].includes(floatValue)
                );
              }}
            />
          </div>
        </div>
      </td>
      <td
        className="no-hover-effect"
        style={{
          borderBottom: "none",
          fontWeight: 600,
          color: isErrorTotalPay ? "#FC5371" : "#1D1858"
        }}>
        {formatMoney(totalPayroll, true)}
      </td>
      <td className="no-hover-effect" style={{ borderBottom: "none" }}>
        {formatMoney(curProfit, true)}
      </td>
      <td className="no-hover-effect" style={{ borderBottom: "none" }}></td>
    </tr>
  );
};

export default memo(RunPayrollStatementMoreTableRow);
