import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "../../axios";
import { actions as toastActions } from "../../store/toast/toast.reducers";
import { actions as authActions } from "../../store/auth/auth.reducers";
import Loading from "../common/Loading";
import ManualApplicationsList from "./manual/ManualApplicationsList";
import ConfirmRecalculateModal from "../modals/ConfirmRecalculateModal";
import { ReactComponent as CloudChange } from "../../assets/images/settings/cloud-change.svg";
import ADPCompanyModal from "../modals/ADPCompanyModal";
import PaychexCompanyModal from "../modals/PaychexCompanyModal";
import { getUser } from "../../store/auth/auth.selectors";

const ManualApplications = () => {
  const dispatch = useDispatch();
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);

  const [editedCompanyType, setEditedCompanyType] = useState();
  const [editedCompany, setEditedCompany] = useState();
  const [isEditLoading, setIsEditLoading] = useState(false);

  const [isChangeLoading, setIsChangeLoading] = useState(false);
  const [changeCompany, setChangeCompany] = useState();

  const userData = useSelector(getUser);

  useEffect(() => {
    if (!isNeedUpdate) {
      fetchCompanies();
    }

    if (isNeedUpdate) {
      setIsNeedUpdate(false);
    }
  }, [isNeedUpdate]);

  const fetchCompanies = () => {
    setLoading(true);
    axios
      .get("/payroll-company-list")
      .then((response) => {
        if (response.success) {
          const sortedItems = response.data.payroll_companies.sort(
            (a, b) => Number(b.isActive) - Number(a.isActive)
          );

          const element = sortedItems.find((el) => el.name === "Gusto");
          const fromIndex = sortedItems.findIndex((el) => el.name === "Gusto");
          sortedItems.splice(fromIndex, 1);
          sortedItems.splice(sortedItems.length, 0, element);

          setCompaniesList(sortedItems);
        }
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      )
      .finally(() => setLoading(false));
  };

  const handleAddClose = () => setEditedCompany();

  const handleOpenEditModal = (item, type) => {
    setEditedCompany(item);
    setEditedCompanyType(type);
  };

  const handleEdit = (values, handleCurClose) => {
    setIsEditLoading(true);
    const formData = new FormData();
    for (const property in values) {
      formData.append(property, values[property]);
    }

    formData.append("_method", "PUT");
    formData.append("payroll_company", editedCompany.payroll_company);
    formData.append("name", editedCompany.name);

    axios
      .post(`/companies/${editedCompany.id}`, formData)
      .then((response) => {
        if (response.success) {
          handleCurClose && handleCurClose();
          setIsNeedUpdate(true);
        }
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({
            code: "error",
            message: err.response?.data?.message || err.message
          })
        )
      )
      .finally(() => setIsEditLoading(false));
  };

  const handleChangeCompany = (item) => setChangeCompany(item);
  const handleCloseChangeModal = () => setChangeCompany();

  const handleCompanyChange = () => {
    setIsChangeLoading(true);

    const formData = new FormData();
    formData.append("payroll_company", changeCompany.id);

    axios
      .post("/set-payroll-company", formData)
      .then((response) => {
        if (response.success) {
          handleCloseChangeModal();
          setIsNeedUpdate(true);
          if (!userData.is_payroll_company_connected) {
            dispatch(authActions.setUserIsPayrollCompanyConnected());
          }
        }
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({
            code: "error",
            message: err.response?.data?.message || err.message
          })
        )
      )
      .finally(() => setIsChangeLoading(false));
  };

  return (
    <div className="profile-companies settings-tabs">
      {loading ? (
        <Loading />
      ) : (
        <div className="table-scroll-mobile payroll-table applications">
          <ManualApplicationsList
            companiesList={companiesList}
            handleEdit={handleOpenEditModal}
            handleChangeCompany={handleChangeCompany}
          />
        </div>
      )}

      <ADPCompanyModal
        open={!!editedCompany && editedCompanyType === "ADP Run"}
        handleClose={handleAddClose}
        editedCompany={editedCompany}
        handleConfirm={handleEdit}
        isLoading={isEditLoading}
      />

      <PaychexCompanyModal
        open={!!editedCompany && editedCompanyType === "Paychex"}
        handleClose={handleAddClose}
        editedCompany={editedCompany}
        handleConfirm={handleEdit}
        isLoading={isEditLoading}
      />

      <ConfirmRecalculateModal
        open={!!changeCompany}
        handleClose={handleCloseChangeModal}
        handleConfirm={handleCompanyChange}
        text="Are you sure you want to change
        payroll company?"
        isLoading={isChangeLoading}
        confirmButtonText="Change Company"
        icon={<CloudChange />}
      />
    </div>
  );
};

export default ManualApplications;
