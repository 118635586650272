import { ReactComponent as Edit } from "../../../assets/images/settings/edit.svg";

const ManualApplicationsCompanyRow = ({ companyData, handleEdit, type }) => {
  const isPaycheck = type === "Paychex";

  return (
    <div className="applications-company-row-item">
      <div className="applications-company-row-item-container">
        <div className="applications-company-row-item-name">{companyData.name}</div>
        {isPaycheck ? (
          <>
            <div className="applications-company-row-item-text co-code">
              <span>Company ID:</span>
              {companyData.paychex_company_id}
            </div>
            <div className="applications-company-row-item-text co-code">
              <span>Level 2:</span>
              {companyData.paychex_level_2_code}
            </div>
            <div className="applications-company-row-item-text co-code">
              <span>Level 3:</span>
              {[true, "true"].includes(companyData.paychex_level_3_code) ? "On" : "Off"}
            </div>
          </>
        ) : (
          <div className="applications-company-row-item-text co-code">
            <span>Co Code:</span>
            {companyData.adp_co_code}
          </div>
        )}
      </div>

      <button
        className="companies-btn applications edit"
        onClick={handleEdit.bind(null, companyData, type)}>
        <Edit />
      </button>
    </div>
  );
};

export default ManualApplicationsCompanyRow;
