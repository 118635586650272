import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  code: null,
  message: null,
  open: false
};

export const { actions, reducer } = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    toast: (state, action) => {
      state.code = action.payload.code;
      state.message = action.payload.message;
      state.open = true;
    },
    resetToast: (state) => {
      state.code = null;
      state.message = null;
      state.open = false;
    }
  }
});

// Action creators are generated for each case reducer function

export default reducer;
