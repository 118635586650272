import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import DeleteIcon from "../../assets/images/run-payroll/delete-filled.svg";

const styles = {
  error: {
    "& div input": {
      borderColor: "red"
    }
  }
};

const EmployeesPayAdjustment = ({
  is_overtime_enabled,
  add_bonus,
  add_per_package,
  add_per_stop,
  register,
  touchedFields,
  errors,
  adding_daily_pay,
  setValue,
  bonuses,
  deleted_bonuses,
  is_work_area_enabled,
  isActive,
  weekly_rate_w2,
  customErrors,
  setCustomErrors
}) => {
  const [bonusesArray, setBonusesArray] = useState(bonuses?.length > 0 ? bonuses : [{ id: 0 }]);

  const handleDailyRateChange = () => {
    if (!isActive || !weekly_rate_w2) {
      return;
    }

    setValue("subtracting_daily_pay", adding_daily_pay === 1 ? 1 : 0, {
      shouldValidate: true,
      shouldDirty: true
    });

    setValue("adding_daily_pay", adding_daily_pay === 1 ? 0 : 1, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  const handleWorkAreaChange = () => {
    if (!isActive) {
      return;
    }

    setValue("is_work_area_enabled", is_work_area_enabled === 1 ? 0 : 1, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  const handleIsOvertimeChange = () => {
    if (!isActive) {
      return;
    }

    setValue("is_overtime_enabled", is_overtime_enabled === 0 ? 1 : 0, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  const handlePerStopChange = () => {
    if (!isActive) {
      return;
    }

    setValue("add_per_stop", add_per_stop === 0 ? 1 : 0, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  const handlePerPackageChange = () => {
    if (!isActive) {
      return;
    }

    setValue("add_per_package", add_per_package === 0 ? 1 : 0, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  const handleBonusChange = () => {
    if (!isActive) {
      return;
    }

    setBonusesArray(bonuses?.length > 1 ? bonuses : [{ id: 0 }]);
    setValue("bonuses", bonuses, {
      shouldValidate: true,
      shouldDirty: true
    });
    setValue("add_bonus", add_bonus === 0 ? 1 : 0, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  const handleAddMore = () => {
    if (!isActive) {
      return;
    }

    setBonusesArray([
      ...bonusesArray,
      {
        per_stop: null,
        daily_threshold: null,
        id: bonusesArray.length + 1
      }
    ]);
  };

  const handleNumberChange = (inputName, e) => {
    const value = e.target?.value.replace(/[^\d.]/g, "").replace(/\.([.\d]+)$/, function (m, m1) {
      return "." + m1.replace(/\./g, "");
    });
    setValue(inputName, value, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  const handleDeleteBonus = (index, e) => {
    e.preventDefault();
    const item = bonuses.find((el, indexN) => indexN === index);
    const newBonusesArr = [...bonusesArray];
    newBonusesArr.splice(index, 1);
    setBonusesArray(newBonusesArr);
    setValue(
      "bonuses",
      bonuses.filter((el, indexN) => indexN !== index),
      {
        shouldValidate: true,
        shouldDirty: true
      }
    );

    if (item.id > 0) {
      setValue("deleted_bonuses", [...deleted_bonuses, item.id]);
    }
  };

  const handleDailyThresholdChange = (index, e) => {
    const value = e.target?.value.replace(/[^\d.]/g, "").replace(/\.([.\d]+)$/, function (m, m1) {
      return "." + m1.replace(/\./g, "");
    });

    if (bonuses?.length > 1) {
      const arrayOfMatches = bonuses
        .map((el) => {
          if (
            el.daily_threshold &&
            +el.daily_threshold > 0 &&
            +e.target?.value > 0 &&
            +el.daily_threshold === +e.target?.value
          ) {
            return true;
          }
          return false;
        })
        .filter((el) => el === true);

      if (arrayOfMatches.length > 0) {
        const errorsData = {
          ...errors,
          [`bonuses_${index}_daily_threshold`]: "Daily threshold must be unique"
        };
        setCustomErrors(errorsData);
      } else {
        const newErrors = { ...errors };
        delete newErrors[`bonuses_${index}_daily_threshold`];
        setCustomErrors(newErrors);
      }
    }

    setValue(`bonuses[${index}][daily_threshold]`, value, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  useEffect(() => {
    if (!weekly_rate_w2 && !adding_daily_pay) {
      setValue("subtracting_daily_pay", 0, {
        shouldValidate: true,
        shouldDirty: true
      });

      setValue("adding_daily_pay", 1, {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  }, [weekly_rate_w2]);

  return (
    <div className="popup-dis-sec">
      <div className="popup-content-sec weekly-salary">
        <div className="form-item full-width weekly-rate-w-two">
          <label>Weekly Rate</label>
          <div className="input-box">
            <span>$</span>
            <TextField
              fullWidth
              type="text"
              placeholder=" "
              name="weekly_rate_w2"
              disabled={!isActive}
              {...register("weekly_rate_w2")}
              onChange={handleNumberChange.bind(null, "weekly_rate_w2")}
              error={touchedFields.weekly_rate_w2 && !!errors.weekly_rate_w2?.message}
              helperText={errors.weekly_rate_w2?.message}
            />
          </div>
        </div>
        <div className="add-bonus-sec weekly-salary">
          <div
            className={`option-toggle add-bonus-toggle ${!weekly_rate_w2 ? "disabled" : ""}`}
            onClick={handleDailyRateChange}>
            <span className={`toggle-btn ${adding_daily_pay === 0 ? "active" : ""}`}></span>
            <span>Weekly Rate Cap</span>
          </div>
        </div>
      </div>

      <div className="form-items-container">
        <div className="form-item">
          <label>Paid Time Off (PTO)</label>
          <div className="input-box">
            <span>$</span>
            <TextField
              fullWidth
              type="text"
              placeholder=" "
              name="paid_time_off"
              disabled={!isActive}
              {...register("paid_time_off")}
              onChange={handleNumberChange.bind(null, "paid_time_off")}
              error={touchedFields.paid_time_off && !!errors.paid_time_off?.message}
              helperText={errors.paid_time_off?.message}
            />
          </div>
        </div>
        <div className="form-item pr2">
          <label>Training Rate</label>
          <div className="input-box">
            <span>$</span>
            <TextField
              fullWidth
              type="text"
              placeholder=" "
              name="training_rate"
              disabled={!isActive}
              {...register("training_rate")}
              onChange={handleNumberChange.bind(null, "training_rate")}
              error={touchedFields.training_rate && !!errors.training_rate?.message}
              helperText={errors.training_rate?.message}
            />
          </div>
        </div>
        <div className="form-item">
          <label>6th Day Bonus</label>
          <div className="input-box">
            <span>$</span>
            <TextField
              fullWidth
              type="text"
              placeholder=" "
              name="six_day_bonus"
              disabled={!isActive}
              {...register("six_day_bonus")}
              onChange={handleNumberChange.bind(null, "six_day_bonus")}
              error={touchedFields.six_day_bonus && !!errors.six_day_bonus?.message}
              helperText={errors.six_day_bonus?.message}
            />
          </div>
        </div>
      </div>

      <div className="add-bonus-sec">
        <div className="option-toggle add-bonus-toggle" onClick={handleIsOvertimeChange}>
          <span className={`toggle-btn ${is_overtime_enabled ? "active" : ""}`}></span>
          <span>Add Overtime</span>
        </div>
      </div>

      <h5 style={{ margin: "12px 0 24px" }}>Weekly Service Worksheet</h5>

      <div className="add-bonus-sec weekly-salary" style={{ marginBottom: "32px" }}>
        <div className={`option-toggle add-bonus-toggle`} onClick={handleWorkAreaChange}>
          <span className={`toggle-btn ${is_work_area_enabled === 1 ? "active" : ""}`}></span>
          <span>Work Area Rate</span>
        </div>
      </div>

      {is_work_area_enabled === 0 && (
        <>
          <h5 style={{ margin: "0 0 24px" }}>Chance Statement</h5>

          <div className="form-items-container" style={{ marginBottom: "13px" }}>
            <div className="form-item">
              <label>Daily Rate</label>
              <div className="input-box">
                <span>$</span>
                <TextField
                  fullWidth
                  type="text"
                  placeholder=" "
                  name="daily_pay"
                  disabled={!isActive}
                  {...register("daily_pay")}
                  onChange={handleNumberChange.bind(null, "daily_pay")}
                  error={touchedFields.daily_pay && !!errors.daily_pay?.message}
                  helperText={errors.daily_pay?.message}
                />
              </div>
            </div>
            <div className="form-item">
              <label>Hourly Rate</label>
              <div className="input-box">
                <span>$</span>
                <TextField
                  fullWidth
                  type="text"
                  placeholder=" "
                  name="hourly_rate"
                  disabled={!isActive}
                  {...register("hourly_rate")}
                  onChange={handleNumberChange.bind(null, "hourly_rate")}
                  error={touchedFields.hourly_rate && !!errors.hourly_rate?.message}
                  helperText={errors.hourly_rate?.message}
                />
              </div>
            </div>
          </div>

          <div className="add-bonus-sec">
            <div className="option-toggle add-bonus-toggle" onClick={handlePerStopChange}>
              <span className={`toggle-btn ${add_per_stop ? "active" : ""}`}></span>
              <span>Add Per Stop </span>
            </div>

            {Boolean(add_per_stop) && (
              <div className="add-bonus-field">
                <div className="three-column">
                  <div className="form-item">
                    <label>Pickup</label>
                    <div className="input-box">
                      <span>$</span>
                      <TextField
                        fullWidth
                        type="text"
                        placeholder="0.00"
                        name="stop_pickup_rate"
                        disabled={!isActive}
                        {...register("stop_pickup_rate")}
                        onChange={handleNumberChange.bind(null, "stop_pickup_rate")}
                        error={touchedFields.stop_pickup_rate && !!errors.stop_pickup_rate?.message}
                        helperText={errors.stop_pickup_rate?.message}
                      />
                    </div>
                  </div>
                  <div className="form-item">
                    <label>Delivery</label>
                    <div className="input-box">
                      <span>$</span>
                      <TextField
                        fullWidth
                        type="text"
                        placeholder="0.00"
                        name="stop_delivery_rate"
                        disabled={!isActive}
                        {...register("stop_delivery_rate")}
                        onChange={handleNumberChange.bind(null, "stop_delivery_rate")}
                        error={
                          touchedFields.stop_delivery_rate && !!errors.stop_delivery_rate?.message
                        }
                        helperText={errors.stop_delivery_rate?.message}
                      />
                    </div>
                  </div>
                  <div className="form-item">
                    <label>E-commerce</label>
                    <div className="input-box">
                      <span>$</span>
                      <TextField
                        fullWidth
                        type="text"
                        placeholder="0.00"
                        name="stop_ecommerce_rate"
                        disabled={!isActive}
                        {...register("stop_ecommerce_rate")}
                        onChange={handleNumberChange.bind(null, "stop_ecommerce_rate")}
                        error={
                          touchedFields.stop_ecommerce_rate && !!errors.stop_ecommerce_rate?.message
                        }
                        helperText={errors.stop_ecommerce_rate?.message}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="add-bonus-sec">
            <div className="option-toggle add-bonus-toggle" onClick={handlePerPackageChange}>
              <span className={`toggle-btn ${add_per_package ? "active" : ""}`}></span>
              <span>Add Per Package </span>
            </div>

            {Boolean(add_per_package) && (
              <div className="add-bonus-field">
                <div className="three-column">
                  <div className="form-item">
                    <label>Pickup</label>
                    <div className="input-box">
                      <span>$</span>
                      <TextField
                        fullWidth
                        type="text"
                        placeholder="0.00"
                        name="package_pickup_rate"
                        disabled={!isActive}
                        {...register("package_pickup_rate")}
                        onChange={handleNumberChange.bind(null, "package_pickup_rate")}
                        error={
                          touchedFields.package_pickup_rate && !!errors.package_pickup_rate?.message
                        }
                        helperText={errors.package_pickup_rate?.message}
                      />
                    </div>
                  </div>
                  <div className="form-item">
                    <label>Delivery</label>
                    <div className="input-box">
                      <span>$</span>
                      <TextField
                        fullWidth
                        type="text"
                        placeholder="0.00"
                        name="package_delivery_rate"
                        disabled={!isActive}
                        {...register("package_delivery_rate")}
                        onChange={handleNumberChange.bind(null, "package_delivery_rate")}
                        error={
                          touchedFields.package_delivery_rate &&
                          !!errors.package_delivery_rate?.message
                        }
                        helperText={errors.package_delivery_rate?.message}
                      />
                    </div>
                  </div>
                  <div className="form-item">
                    <label>E-commerce</label>
                    <div className="input-box">
                      <span>$</span>
                      <TextField
                        fullWidth
                        type="text"
                        placeholder="0.00"
                        name="package_ecommerce_rate"
                        disabled={!isActive}
                        {...register("package_ecommerce_rate")}
                        onChange={handleNumberChange.bind(null, "package_ecommerce_rate")}
                        error={
                          touchedFields.package_ecommerce_rate &&
                          !!errors.package_ecommerce_rate?.message
                        }
                        helperText={errors.package_ecommerce_rate?.message}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="add-bonus-sec">
            <div className="option-toggle add-bonus-toggle" onClick={handleBonusChange}>
              <span className={`toggle-btn ${add_bonus ? "active" : ""}`}></span>
              <span>Add Daily Threshold</span>
            </div>

            {Boolean(add_bonus) && (
              <div className="add-bonus-field bonus">
                {bonusesArray.map((el, index) => (
                  <div className="form-item-container" key={index}>
                    <div className="form-item">
                      <label
                        className={!!customErrors[`bonuses_${index}_daily_threshold`] && "error"}>
                        Daily Threshold
                      </label>
                      <div className="input-box">
                        <TextField
                          sx={customErrors[`bonuses_${index}_daily_threshold`] ? styles.error : {}}
                          fullWidth
                          type="text"
                          placeholder="100"
                          name={`bonuses[${index}][daily_threshold]`}
                          disabled={!isActive}
                          {...register(`bonuses[${index}][daily_threshold]`)}
                          onChange={handleDailyThresholdChange.bind(null, index)}
                          error={
                            touchedFields.bonuses?.[index]?.daily_threshold &&
                            !!customErrors[`bonuses_${index}_daily_threshold`]
                          }
                          helperText={customErrors[`bonuses_${index}_daily_threshold`]}
                        />
                      </div>
                    </div>
                    <div className="form-item">
                      <label>Per Stop</label>
                      <div className="input-box">
                        <span>$</span>
                        <TextField
                          fullWidth
                          type="text"
                          placeholder="1.00"
                          name={`bonuses[${index}][per_stop]`}
                          disabled={!isActive}
                          {...register(`bonuses[${index}][per_stop]`)}
                          onChange={handleNumberChange.bind(null, `bonuses[${index}][per_stop]`)}
                          error={touchedFields.per_stop && !!errors.per_stop?.message}
                          helperText={errors.per_stop?.message}
                        />
                      </div>
                    </div>
                    <button
                      disabled={!isActive}
                      className="document-more-btn menu-item"
                      onClick={handleDeleteBonus.bind(null, index)}>
                      <img src={DeleteIcon} alt="" />
                    </button>
                  </div>
                ))}

                {bonusesArray.length < 3 && (
                  <div className="bonus-add-more" onClick={handleAddMore}>
                    <span>+</span> Add more
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default EmployeesPayAdjustment;
