
const InsightsTotalBlock = ({ itemData }) => {
    const { icon, count, name } = itemData;

    return (
        <li>
            <img src={icon} alt="" />
            <h5>{count}</h5>
            <p>{name}</p>
        </li>
    );
}

export default InsightsTotalBlock;