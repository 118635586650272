import { useEffect, useState } from "react";
import dayjs from "dayjs";
import axios from "../../axios";
import Loading from "./../common/Loading";
import BackButton from "../common/BackButton";
import BackArrowBig from "../../assets/images/run-payroll-preview/back-arrow-big.png";

const EmployeesModalPayHistory = ({ editedUserId, handlePayHistoryClose }) => {
  const [loading, setLoading] = useState(false);
  const [payHistory, setPayHistory] = useState({});

  useEffect(() => {
    if (editedUserId) {
      setLoading(true);
      axios
        .get(`/employee/${editedUserId}/history`)
        .then((res) => {
          setPayHistory(res.data.history);
        })
        .catch(() => handlePayHistoryClose())
        .finally(() => setLoading(false));
    }
  }, []);

  const keyArray = Object.keys(payHistory);

  const getUserName = (user, source) => {
    if (source !== 'platform') {
      return source.charAt(0).toUpperCase() + source.slice(1);
    }

    if (user) {
      return user.first_name + '' + user.last_name;
    }

    return 'Deleted User';
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <BackButton
        text={
          <>
            <img src={BackArrowBig} alt="" /> Back to edit
          </>
        }
        onClick={handlePayHistoryClose}
        className="margin-bottom-two"
      />

      {keyArray.length < 1 ? (
        <div className="no-pay-history">No Pay History</div>
      ) : (
        <>
          {keyArray.map((el) => (
            <div key={el} className="pay-history-item">
              <h4>
                {dayjs(payHistory[el][0].created_at).format('YYYY-MM-DD HH:mm')} -{' '}
                {getUserName(payHistory[el][0].user, payHistory[el][0].source)}
              </h4>

              <div className="table-scroll-mobile payroll-table pay-history-table">
                <table>
                  <thead>
                    <tr>
                      <th>Field name</th>
                      <th>Old value</th>
                      <th>New value</th>
                    </tr>
                  </thead>

                  <tbody>
                    {payHistory[el].map((item) => (
                      <tr key={item.id}>
                        <td>{item.field}</td>
                        <td>{item.old_value}</td>
                        <td>{item.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}{" "}
        </>
      )}
    </>
  );
};

export default EmployeesModalPayHistory;
