import { useState } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import { actions as toastActions } from "../../store/toast/toast.reducers";
import Loading from "../common/Loading";
import axios from "../../axios";

const styles = {
  error: {
    "& div input": {
      borderColor: "red !important"
    }
  }
};

const schema = yup.object().shape({
  email: yup.string().email("Invalid format").required("Email is required!")
});

const ForgotPassword = ({ handleSignUp, handleSignIn }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, touchedFields }
  } = useForm({
    mode: "all",
    defaultValues: {
      email: ""
    },
    resolver: yupResolver(schema)
  });

  const onSubmit = (value) => {
    setLoading(true);

    axios
      .post("/auth/password/email", value)
      .then((res) => {
        handleSignIn();
        dispatch(toastActions.toast({ code: "success", message: res.data.message }));
        reset();
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      )
      .finally(() => setLoading(false));
  };

  return (
    <div className="inner-sec">
      <h1>Reset Password</h1>
      <form className="login-form">
        <div className="input-items">
          <label className={errors.email?.message && "error"}>Email Address *</label>
          <TextField
            sx={errors.primary_station?.message ? styles.error : {}}
            type="email"
            name="email"
            required
            {...register("email")}
            error={touchedFields.email && !!errors.email?.message}
            helperText={errors.email?.message}
          />
        </div>
        <div className="submit-btn">
          {loading ? (
            <Loading />
          ) : (
            <input
              type="submit"
              value="Send Password Reset Link"
              disabled={loading}
              onClick={handleSubmit(onSubmit)}
            />
          )}
        </div>
      </form>

      <div className="have-account-sec">
        Don't have an account ? <button onClick={handleSignUp}>Sign up</button>
      </div>
    </div>
  );
};

export default ForgotPassword;
