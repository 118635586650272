import { MenuItem, Select } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import RunPayrollTable from "./RunPayrollTable";

const RunPayrollContent = ({
  payrolls,
  handleRangeChange,
  displayBy,
  handleSortChange,
  activeSortedItem
}) => {
  return (
    <div className="run-payroll-data">
      <div className="payroll-top">
        <div className="payroll-result-count">{payrolls.length} Payroll(s)</div>

        <Select
          className="payroll-table-select"
          value={displayBy}
          onChange={handleRangeChange}
          IconComponent={KeyboardArrowDown}>
          <MenuItem value={4}>Month</MenuItem>
          <MenuItem value={24}>6 Month</MenuItem>
          <MenuItem value={48}>Year</MenuItem>
        </Select>
      </div>

      <RunPayrollTable
        payrolls={payrolls}
        displayBy={displayBy}
        handleSortChange={handleSortChange}
        activeSortedItem={activeSortedItem}
      />
    </div>
  );
};

export default RunPayrollContent;
