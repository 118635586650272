// import { useSelector } from "react-redux";
import AccountIcon1Big from "../../assets/images/settings/account-icon-1-big.png";
import AccountIcon2Big from "../../assets/images/settings/account-icon-2-big.png";
import AccountIcon3Big from "../../assets/images/settings/account-icon-3-big.png";
import ExpensesIcon from "../../assets/images/settings/expenses.png";
import RankingBig from "../../assets/images/settings/ranking-big.png";
import WorkspaceIcon2Big from "../../assets/images/settings/workspace-icon-2-big.png";
// import SettingsBig from "../../assets/images/settings/settings-big.png";
// import { isSuperAdmin } from "../../store/auth/auth.selectors";

const SettingsLeftMenu = ({ handlePageChange, page }) => {
  const isActive = (pageKey) => (page === pageKey ? "active" : "");

  // const isUserSuperAdmin = useSelector(isSuperAdmin);

  return (
    <div className="profile-sidebar">
      <div className="sidebar-menus">
        <h6>Account</h6>
        <ul>
          <li>
            <button
              onClick={handlePageChange.bind(null, "profile")}
              className={isActive("profile")}>
              <img src={AccountIcon1Big} alt="" />
              My Profile
            </button>
          </li>
          <li>
            <button
              onClick={handlePageChange.bind(null, "subscription")}
              className={isActive("subscription")}>
              <img src={RankingBig} alt="" />
              Subscription
            </button>
          </li>
        </ul>
      </div>

      <div className="sidebar-menus">
        <h6>Organization</h6>
        <ul>
          <li>
            <button
              onClick={handlePageChange.bind(null, "companies")}
              className={isActive("companies")}>
              <img src={AccountIcon2Big} alt="" />
              Company
            </button>
          </li>
          <li>
            <button onClick={handlePageChange.bind(null, "users")} className={isActive("users")}>
              <img src={WorkspaceIcon2Big} alt="" />
              Users
            </button>
          </li>
          <li>
            <button
              onClick={handlePageChange.bind(null, "preferences")}
              className={isActive("preferences")}>
              <img src={AccountIcon3Big} alt="" />
              Preference
            </button>
          </li>
          <li>
            <button
              onClick={handlePageChange.bind(null, "fixed-expenses")}
              className={isActive("fixed-expenses")}>
              <img src={ExpensesIcon} alt="" />
              Fixed Expense
            </button>
          </li>
        </ul>
      </div>
      {/* {isUserSuperAdmin && (
        <div className="sidebar-menus">
          <h6>Admin</h6>
          <ul></ul>
        </div>
      )} */}
    </div>
  );
};

export default SettingsLeftMenu;
