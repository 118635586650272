import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";

dayjs.extend(weekday);

export const getPayAdjustment = (employee) => {
    return employee?.rates?.weekly_rate_w2 ? "Weekly" : "Daily";
};

export const getThisWeek = () => {
    const curDayToInWeek = dayjs().day();

    if (curDayToInWeek < 3) {
        return dayjs().weekday(6).subtract(3, "week").format("YYYY-MM-DD");
    } else {
        return dayjs().weekday(6).subtract(2, "week").format("YYYY-MM-DD");
    }
};

export const initiateFileDownloadFromBlob = (blob, fileName) => {
    // Create a URL for the Blob object.
    const fileURL = window.URL.createObjectURL(blob);

    // Get the extension file
    const extension = blob.type.split('/')[1];

    // Create a temporary download link element.
    const downloadLink = document?.createElement('a');
    downloadLink.href = fileURL;
    downloadLink.setAttribute('download', `${fileName}.${extension}`); // Set the download file name.

    // Append the link to the document and trigger the download.
    document?.body.appendChild(downloadLink);
    downloadLink.click();

    // Clean up by removing the link and revoking the created URL.
    document?.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(fileURL);
};