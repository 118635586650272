import { memo } from "react";
import { ReactComponent as FleetWageLogoEev } from "../../assets/images/fleet-wage-logo-rev.svg";
import { ReactComponent as FleetWageLogo } from "../../assets/images/fleet-wage-logo.svg";

const LeftMenuHeader = ({ isCollapsed }) => {
  return (
    <div className="main-logo">
      <a href="/" className="logo-item">
        {isCollapsed ? <FleetWageLogo /> : <FleetWageLogoEev />}
      </a>
    </div>
  );
};

export default memo(LeftMenuHeader);
