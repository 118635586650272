import "../assets/css/run-payroll.css";

import { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import axios from "../axios";
import { actions as toastActions } from "../store/toast/toast.reducers";
import { actions } from "../store/payroll-preview/payroll-preview.reducers";
import { actions as dataActions } from "../store/data/data.reducers";
import { actions as breadCrumbsActions } from "../store/bread-crumbs/bread-crumbs.reducers";
import Loading from "../components/common/Loading";
import RunPayrollStatementTable from "../components/run-payroll-preview/RunPayrollStatementTable";
import { formatMoney, formatNumber, calculateTotalPayAndPerStop, calculateProfit } from "../utils";
import { getBreadCrumbs } from "../store/bread-crumbs/bread-crumbs.selectors";
import ConfirmDeleteModal from "./../components/modals/ConfirmDeleteModal";

const RunPayrollStatement = () => {
  const dispatch = useDispatch();

  const { payrollId, statementId } = useParams();

  const breadCrumbs = useSelector(getBreadCrumbs);

  const [loading, setLoading] = useState(false);
  const [isEditComplete, setIsEditComplete] = useState(false);
  const [deleteUser, setDeleteUser] = useState();
  const [sortItem, setSortItem] = useState();

  useEffect(() => {
    fetchStatementList();

    fetchCompanies();
    fetchStations();
    fetchPositions();
  }, []);

  useEffect(() => {
    if (isEditComplete) {
      fetchStatementList();
      setIsEditComplete(false);
    }
  }, [isEditComplete]);

  const fetchCompanies = () => {
    axios
      .get("/company-list")
      .then((response) => {
        dispatch(dataActions.setCompanies(response.data.companies));
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      );
  };

  const fetchStations = () => {
    axios
      .get("/station-list")
      .then((response) => {
        dispatch(dataActions.setStations(response.data.stations));
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      );
  };

  const fetchPositions = () => {
    axios
      .get("/settings/positions")
      .then((response) => {
        dispatch(dataActions.setPositions(response.data.positions));
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      );
  };

  const fetchStatementList = () => {
    setLoading(true);

    axios
      .get(`statement/${statementId}`)
      .then((response) => {
        const statementData = response.data;
        const totalW2 = statementData.weekly_summary.reduce((acc, el) => {
          const { elTotalPay } = calculateTotalPayAndPerStop(el, statementData.station_id);

          if (typeof acc === "number") {
            return acc + elTotalPay;
          }

          return elTotalPay;
        }, 0);

        const profit = calculateProfit(totalW2, statementData);

        const statementTotalStats = [
          {
            name: "Employees",
            count: statementData.total_employees
          },
          {
            name: "Stops",
            count: formatNumber(statementData.total_stops),
            number: statementData.total_stops
          },
          {
            name: "Fuel",
            count: formatMoney(+statementData.total_fuel === 0 ? 0 : statementData.total_fuel),
            percentage:
              ((+statementData.total_fuel / statementData.total_charges) * 100).toFixed(2) || 0
          },
          {
            name: "Expenses",
            count: formatMoney(
              +statementData.total_expenses === 0 ? 0 : statementData.total_expenses
            ),
            number: statementData.total_expenses,
            percentage:
              ((+statementData.total_expenses / statementData.total_charges) * 100).toFixed(2) || 0
          },
          {
            name: "W-2",
            count: formatMoney(totalW2),
            percentage: ((totalW2 / statementData.total_charges) * 100).toFixed(2) || 0
          },
          // TODO total_charges - revenue need to check
          {
            name: "Revenue",
            count: formatMoney(statementData.total_charges),
            number: statementData.total_charges
          },
          {
            name: "Per Stop",
            count: formatMoney(statementData.per_stop, true)
          },
          {
            name: "Profit",
            count: formatMoney(profit),
            percentage: ((profit / statementData.total_charges) * 100).toFixed(2) || 0
          }
        ];

        if (breadCrumbs.length === 0 || breadCrumbs.length > 2) {
          dispatch(
            breadCrumbsActions.setBreadCrumbs([
              {
                name: "Payroll",
                url: `/run-payroll`
              },
              {
                name: `${dayjs(statementData.date).format("MM/DD/YYYY")} - ${dayjs(
                  statementData.date
                )
                  .add(6, "d")
                  .format("MM/DD/YYYY")}`,
                url: `/run-payroll-preview/${payrollId}`
              },
              {
                name: `${statementData.company_name}, ${statementData.station_name} ${statementData.station_id}`
              }
            ])
          );
        } else {
          dispatch(
            breadCrumbsActions.setBreadCrumbs([
              ...breadCrumbs,
              {
                name: `${statementData.company_name}, ${statementData.station_name} ${statementData.station_id}`
              }
            ])
          );
        }

        const statementEmployeesWithTotalPay = statementData.weekly_summary.map((el) => {
          const { elTotalPay, perStop } = calculateTotalPayAndPerStop(el, statementData.station_id);

          return {
            ...el,
            totalPay: elTotalPay,
            perStop: perStop
          };
        });

        batch(() => {
          dispatch(actions.setStatementTotalStats(statementTotalStats));
          dispatch(actions.setStatementAllData(statementData));
          dispatch(actions.setStatementEmployees(statementEmployeesWithTotalPay));
        });
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      )
      .finally(() => setLoading(false));
  };

  const handleDeleteEmployee = () => {
    axios
      .delete(`/statement/${statementId}/remove-employee/${deleteUser.employee_id}`)
      .then(() => {
        dispatch(
          toastActions.toast({
            code: "success",
            message: "Employee deleted successfully from this statement"
          })
        );
        fetchStatementList();
        handleCloseDeleteModal();
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      );
  };

  const handleOpenDeleteModal = (user) => setDeleteUser(user);
  const handleCloseDeleteModal = () => setDeleteUser();

  return (
    <>
      {loading ? (
        <div className="loader-wrapper">
          <Loading />
        </div>
      ) : (
        <RunPayrollStatementTable
          loading={loading}
          setIsEditComplete={setIsEditComplete}
          handleDeleteEmployee={handleOpenDeleteModal}
          setSortItem={setSortItem}
          sortItem={sortItem}
          statementId={statementId}
        />
      )}

      <ConfirmDeleteModal
        open={!!deleteUser}
        handleClose={handleCloseDeleteModal}
        handleConfirm={handleDeleteEmployee}
        text="Are you sure delete this Employee from statement?"
      />
    </>
  );
};

export default RunPayrollStatement;
