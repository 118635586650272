import "../assets/css/onboard.css";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { HighlightOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "../axios";
import LoginLogo from "../components/common/LoginLogo";
import { actions as toastActions } from "../store/toast/toast.reducers";
import { actions } from "../store/auth/auth.reducers";

const styles = {
  error: {
    "& div input": {
      borderColor: "red"
    }
  }
};

const OnboardCompanyInfo = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();

  const [count, setCount] = useState(1);
  const [saveLoading, setSaveLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { touchedFields }
  } = useForm({
    mode: "all",
    defaultValues: {
      companies: [
        {
          name: "",
          stations: [
            {
              name: "",
              csa: ""
            }
          ]
        }
      ]
    }
  });

  const { companies } = watch();

  const handleIncrease = () => {
    if (count < 10) {
      setCount(count + 1);
      setValue(
        "companies",
        [
          ...companies,
          {
            name: "",
            stations: [
              {
                name: "",
                csa: ""
              }
            ]
          }
        ],
        {
          shouldValidate: true,
          shouldDirty: true
        }
      );
    }
  };

  const handleReduce = (index) => {
    if (count > 1) {
      const newCompanies = [...companies];

      if (index) {
        newCompanies.splice(index, 1);
      } else {
        newCompanies.splice(-1);
      }

      setCount(count - 1);

      setValue("companies", newCompanies, {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  };

  const handleAddStation = (index, e) => {
    e.preventDefault();
    setValue(
      `companies[${index}].stations`,
      [
        ...companies[index].stations,
        {
          name: "",
          csa: ""
        }
      ],
      {
        shouldValidate: true,
        shouldDirty: true
      }
    );
  };

  const handleRemoveStation = (index, itemIndex, e) => {
    e.preventDefault();
    const newStations = [...companies[index].stations];
    newStations.splice(itemIndex, 1);

    setValue(`companies[${index}].stations`, newStations, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  const handleCompanyClose = (index) => {
    if (count !== 1) {
      handleReduce(index);
    }
  };

  const handleServiceAreaChange = (index, itemIndex, e) => {
    if (companies.length > 0) {
      const stationsArray = companies.reduce((acc, item) => [...acc, ...item.stations], []);

      const arrayOfMatches = stationsArray
        .map((el) => {
          if (el.csa && el.csa.length > 0 && e.target.value > 0 && el.csa === e.target.value) {
            return true;
          }
          return false;
        })
        .filter((el) => el === true);

      if (arrayOfMatches.length > 0) {
        const errorsData = {
          ...errors,
          [`companies_${index}_stations_${itemIndex}_csa`]: "Id must be unique"
        };
        setErrors(errorsData);
      } else if (e.target.value < 1) {
        const errorsData = {
          ...errors,
          [`companies_${index}_stations_${itemIndex}_csa`]: "CSA required"
        };
        setErrors(errorsData);
      } else {
        const newErrors = { ...errors };
        delete newErrors[`companies_${index}_stations_${itemIndex}_csa`];
        setErrors(newErrors);
      }
    }

    setValue(`companies[${index}][stations][${itemIndex}][csa]`, e.target.value, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  const handleStationNameChange = (index, itemIndex, e) => {
    if (e.target.value.length < 1) {
      const errorsData = {
        ...errors,
        [`companies_${index}_stations_${itemIndex}_name`]: "Station required"
      };
      setErrors(errorsData);
    } else {
      const newErrors = { ...errors };
      delete newErrors[`companies_${index}_stations_${itemIndex}_name`];
      setErrors(newErrors);
    }

    setValue(`companies[${index}][stations][${itemIndex}][name]`, e.target.value, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  const handleCompaniesNameChange = (index, e) => {
    if (e.target.value.length < 1) {
      const errorsData = {
        ...errors,
        [`companies_${index}_name`]: "Entity Name required"
      };
      setErrors(errorsData);
    } else {
      const newErrors = { ...errors };
      delete newErrors[`companies_${index}_name`];
      setErrors(newErrors);
    }

    setValue(`companies[${index}][name]`, e.target.value, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  const onSubmit = (values) => {
    const isDisabled =
      companies?.reduce((acc, el) => {
        if (!el.name || el.stations.find((el) => !el.csa || !el.name)) {
          return acc + 1;
        }
        return acc;
      }, 0) > 0;

    if (isDisabled) {
      const errorObject = companies.reduce((acc, el, index) => {
        const errorsFromStation = el.stations.reduce((accum, elem, itemIndex) => {
          const errorsData = {
            ...(!elem.name.length && {
              [`companies_${index}_stations_${itemIndex}_name`]: "Station required"
            }),
            ...(!elem.csa && { [`companies_${index}_stations_${itemIndex}_csa`]: "CSA required" }),
            ...(!el.name.length && { [`companies_${index}_name`]: "Entity Name required" })
          };

          return { ...accum, ...errorsData };
        }, {});

        return { ...acc, ...errorsFromStation };
      }, {});
      setErrors({ ...errors, ...errorObject });

      dispatch(
        toastActions.toast({ code: "error", message: "All fields marked with * must be filled" })
      );
      return;
    }

    setSaveLoading(true);
    axios
      .post("auth/setup-organization", {
        companies: values.companies
      })
      .then((response) => {
        if (response.success) {
          dispatch(actions.setUserCompaniesCount(values.companies.length));
          dispatch(
            toastActions.toast({
              code: "success",
              message: "The companies onboarding was successful"
            })
          );
          nav("/");
        }
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      )
      .finally(() => setSaveLoading(false));
  };

  return (
    <>
      <LoginLogo />

      <div className="onboard-main-sec">
        <div className="container">
          <div className="onboard-inner-sec">
            <div className="onboard-head">
              <h1>How many companies do you have?</h1>
              <p>Add your company information with station name</p>
            </div>

            <form className="onboard-form">
              <div className="number">
                <span className="minus" onClick={handleReduce.bind(null, null)}>
                  -
                </span>
                <input type="text" value={count} />
                <span className="plus" onClick={handleIncrease}>
                  +
                </span>
              </div>

              {companies.map((el, index) => (
                <div className="onboard-form-fieldset" key={index}>
                  <div className="onboard-fieldset-top-bar">
                    <h2>Company</h2>
                    <div className="onboard-header-buttons">
                      <HighlightOff
                        className="onboard-close"
                        onClick={handleCompanyClose.bind(null, index)}
                      />
                    </div>
                  </div>

                  <div className="form-item">
                    <label className={!!errors[`companies_${index}_name`] && "error"}>
                      Entity Name *
                    </label>
                    <div className="input-box">
                      <TextField
                        sx={{
                          ...(errors[`companies_${index}_name`] ? styles.error : {})
                        }}
                        fullWidth
                        type="text"
                        placeholder=" "
                        name={`companies[${index}][name]`}
                        {...register(`companies[${index}][name]`)}
                        onChange={handleCompaniesNameChange.bind(null, index)}
                        error={
                          touchedFields.companies?.[index]?.name &&
                          !!errors[`companies_${index}_name`]
                        }
                        helperText={errors[`companies_${index}_name`]}
                      />
                    </div>
                  </div>

                  <div className="onboard-fieldset-station">
                    {companies[index].stations.map((item, itemIndex) => (
                      <div key={itemIndex} className="onboard-fieldset-item">
                        <div className="form-item">
                          <label
                            className={
                              !!errors[`companies_${index}_stations_${itemIndex}_name`] && "error"
                            }>
                            Station *
                          </label>
                          <div className="input-box">
                            <TextField
                              sx={{
                                ...(errors[`companies_${index}_stations_${itemIndex}_name`]
                                  ? styles.error
                                  : {})
                              }}
                              fullWidth
                              type="text"
                              placeholder=" "
                              name={`companies[${index}][stations][${itemIndex}][name]`}
                              {...register(`companies[${index}][stations][${itemIndex}][name]`)}
                              onChange={handleStationNameChange.bind(null, index, itemIndex)}
                              error={
                                touchedFields.companies?.[index]?.stations?.[itemIndex]?.name &&
                                !!errors[`companies_${index}_stations_${itemIndex}_name`]
                              }
                              helperText={errors[`companies_${index}_stations_${itemIndex}_name`]}
                            />
                          </div>
                        </div>
                        <div className="form-item station-cancel-field">
                          <label
                            className={
                              !!errors[`companies_${index}_stations_${itemIndex}_csa`] && "error"
                            }>
                            Contracted Service Area *
                          </label>
                          <div className="input-box">
                            <TextField
                              sx={{
                                width: "78%",
                                ...(errors[`companies_${index}_stations_${itemIndex}_csa`]
                                  ? styles.error
                                  : {})
                              }}
                              type="number"
                              placeholder=" "
                              name={`companies[${index}][stations][${itemIndex}][csa]`}
                              {...register(`companies[${index}][stations][${itemIndex}][csa]`)}
                              onChange={handleServiceAreaChange.bind(null, index, itemIndex)}
                              error={
                                touchedFields.companies?.[index]?.stations?.[itemIndex]?.csa &&
                                !!errors[`companies_${index}_stations_${itemIndex}_csa`]
                              }
                              helperText={errors[`companies_${index}_stations_${itemIndex}_csa`]}
                            />
                            <button
                              onClick={handleRemoveStation.bind(null, index, itemIndex)}
                              className="station-field-cancel">
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {companies[index].stations.length < 20 && (
                    <div className="onboard-add-more" onClick={handleAddStation.bind(null, index)}>
                      <button>
                        <span className="plus">+</span> Add station
                      </button>
                    </div>
                  )}
                </div>
              ))}

              <button
                onClick={handleSubmit(onSubmit)}
                className="btn-style full-width"
                type="submit"
                disabled={saveLoading}>
                Save & Get Started
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnboardCompanyInfo;
