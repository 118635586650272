const FilterBy = ({ title, filterBy, handleFilterByChange }) => {
  return (
    <div className="subtop-title-bar">
      <h4>{title}</h4>
      <ul>
        <li>
          <button
            className={`revenue-average ${filterBy === 4 ? "active" : ""}`}
            onClick={handleFilterByChange.bind(null, 4)}>
            Last 4 Payrolls
          </button>
        </li>
        <li>
          <button
            className={`${filterBy === 1 ? "active" : ""}`}
            onClick={handleFilterByChange.bind(null, 1)}>
            Last Payroll
          </button>
        </li>
      </ul>
    </div>
  );
};

export default FilterBy;
