import { useEffect, useState } from "react";
import "../../assets/css/insights.css";

import TitleInfo from "../../components/common/TitleInfo";
// import InsightsTable from "../components/Insights/InsightsTable";
import InsightsTotalBlock from "../../components/Insights/InsightsTotalBlock";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/common/Loading";
import axios from "../../axios";
import { actions } from "../../store/insights/insights.reducers";
import { actions as toastActions } from "../../store/toast/toast.reducers";
import { getInsightsData } from "../../store/insights/insights.selectors";
import TotalCompaniesIcon from "../../assets/images/insights/total-companies.png";
import TotalStationsIcon from "../../assets/images/insights/total-stations.png";
import TotalEmployeesIcon from "../../assets/images/insights/total-employees.png";

const Insights = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const insightsData = useSelector(getInsightsData);

  useEffect(() => {
    setLoading(true);

    axios
      .get("/admin/insights")
      .then((response) => {
        const insightsDataArr = [
          {
            name: "Total Companies",
            icon: TotalCompaniesIcon,
            count: response.data.company_count
          },
          {
            name: "Total Stations",
            icon: TotalStationsIcon,
            count: response.data.station_count
          },
          {
            name: "Total Employees",
            icon: TotalEmployeesIcon,
            count: response.data.employee_count
          }
        ];

        dispatch(actions.setInsightsData(insightsDataArr));
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      )
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <TitleInfo
        title="Insights"
        subTitle="Insights of Stations"
        // buttons={[
        //     {
        //         name: '+ Add New Company',
        //         class: 'btn-style add-company-btn',
        //     }
        // ]}
      />

      <div className="company-data-list">
        {loading ? (
          <Loading />
        ) : (
          <div className="company-total-info">
            <ul>
              {insightsData?.map((el) => (
                <InsightsTotalBlock key={el.name} itemData={el} />
              ))}
            </ul>
          </div>
        )}

        {/* <div className="company-data-sec">
          <h4>Companies</h4>

          <div className="company-data-list">
            <InsightsTable />
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Insights;
