import { batch, useDispatch } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import { actions } from "../../store/auth/auth.reducers";
import { actions as toastActions } from "../../store/toast/toast.reducers";
import GoogleBig from "../../assets/images/login/google-big.png";
import AppleBig from "../../assets/images/login/apple-big.png";
import Loading from "../common/Loading";
import axios from "../../axios";

const styles = {
  error: {
    "& div input": {
      borderColor: "red !important"
    }
  }
};

const schema = yup.object().shape({
  email: yup.string().email("Invalid format").required("Email is required!"),
  password: yup
    .string()
    .min(8, "There should be at least 8 characters")
    .required("Password is required!")
  // .matches(
  //   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
  //   "Password must contain at least 8 characters, one uppercase, one number and one special case character"
  // ),
});

const SignIn = ({ handleSignUp, handleForgot }) => {
  const dispatch = useDispatch();
  const nav = useNavigate();

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, touchedFields }
  } = useForm({
    mode: "all",
    defaultValues: {
      email: "",
      password: ""
    },
    resolver: yupResolver(schema)
  });

  const onSubmit = (values) => {
    setLoading(true);

    axios
      .post("/auth/login", values)
      .then((res) => {
        if (res && !res.success) {
          dispatch(toastActions.toast({ code: "error", message: res.message }));
          reset();
          return;
        }

        const data = res?.data;
        batch(() => {
          dispatch(actions.setToken(data.token));
          dispatch(actions.loginUser(data.user));
          localStorage.setItem("user", JSON.stringify(data.user));
        });
        localStorage.setItem("token", data.token);

        if (data.user.companies_count === 0) {
          nav("/onboard-company");
          return;
        }

        nav("/");
        return;
      })
      .catch((err) => {
        if (err.response?.status !== 401 && err.response?.status !== 422) {
          dispatch(
            toastActions.toast({
              code: "error",
              message:
                err.response?.data?.message ||
                `${Object.values(err.response.data.errors).join("")}` ||
                err.message
            })
          );
        }
      })
      .finally(() => setLoading(false));
  };

  const handleGoogleLogin = () => {
    axios
      .get("/auth/social/google/redirect")
      .then((res) => {
        if (res) {
          window.open(res, "_self");
        }
      })
      .catch((err) => {
        if (err.response?.status !== 401 && err.response?.status !== 422) console.log(err);
      });
  };

  return (
    <div className="inner-sec">
      <h1>Get Started Now</h1>
      <p>Enter your credentials to access your account</p>
      <div className="login-options">
        <button onClick={handleGoogleLogin}>
          <img src={GoogleBig} alt="" /> Log in with Google
        </button>
        <button disabled>
          <img src={AppleBig} alt="" /> Log in with Apple
        </button>
      </div>
      <div className="or-text">
        <span>or</span>
      </div>
      <form className="login-form">
        <div className="input-items">
          <label className={errors.email?.message && "error"}>Email Address *</label>
          <TextField
            sx={errors.email?.message ? styles.error : {}}
            type="email"
            name="email"
            required
            {...register("email")}
            error={touchedFields.email && !!errors.email?.message}
            helperText={errors.email?.message}
          />
        </div>
        <div className="input-items">
          <label className={errors.password?.message && "error"}>Password *</label>
          <TextField
            sx={errors.password?.message ? styles.error : {}}
            type="password"
            name="password"
            required
            {...register("password")}
            error={touchedFields.password && !!errors.password?.message}
            helperText={errors.password?.message}
          />
        </div>
        <div className="submit-btn">
          {loading ? (
            <Loading />
          ) : (
            <input
              type="submit"
              value="Sign in"
              disabled={loading}
              onClick={handleSubmit(onSubmit)}
            />
          )}
        </div>
      </form>
      <div className="have-account-sec-wrapper">
        <div className="have-account-sec">
          Don&apos;t have an account ? <button onClick={handleSignUp}>Sign up</button>
        </div>
        <div className="have-account-sec">
          <button onClick={handleForgot}>Forgot Your Password?</button>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
