import "../assets/css/run-payroll.css";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import TitleInfo from "./../components/common/TitleInfo";
import RunPayrollDropzone from "../components/run-payroll/RunPayrollDropzone";
import RunPayrollUploadProcess from "../components/run-payroll/RunPayrollUploadProcess";
import RunPayrollContent from "../components/run-payroll/RunPayrollContent";
import { actions as toastActions } from "../store/toast/toast.reducers";
import axios from "../axios";
import EmptyState from "../components/run-payroll/EmptyState";
import { getPayrollList } from "../store/payroll/payroll.selectors";
import { actions } from "../store/payroll/payroll.reducers";
import Loading from "../components/common/Loading";
import { actions as breadCrumbsActions } from "../store/bread-crumbs/bread-crumbs.reducers";
import { getUser } from "../store/auth/auth.selectors";
import { useTableSortItem } from "../hooks/useTableSortItem";

const RunPayroll = () => {
  const [filesFormData, setFilesFormData] = useState();
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [displayBy, setDisplayBy] = useState(4);
  const { sortItem, setSortItem } = useTableSortItem("run-payroll");

  const userData = useSelector(getUser);

  const payrolls = useSelector(getPayrollList);

  useEffect(() => {
    dispatch(
      breadCrumbsActions.setBreadCrumbs([
        {
          name: "Payroll",
          url: `/run-payroll`
        }
      ])
    );
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => fetchPayrollList(), 500);

    return () => {
      clearTimeout(handler);
    };
  }, [displayBy, sortItem]);

  useEffect(() => {
    if (filesFormData) {
      setIsUploading(true);
      axios
        .post("/payroll/upload", filesFormData)
        .then((res) => {
          fetchPayrollList();
          dispatch(toastActions.toast({ code: "success", message: res.message }));
        })
        .catch((err) =>
          dispatch(
            toastActions.toast({
              code: "error",
              message: err.response?.data?.message || err.message
            })
          )
        )
        .finally(() => setIsUploading(false));
    }
  }, [filesFormData]);

  const fetchPayrollList = () => {
    setLoading(true);

    axios
      .get(
        `/payroll?filter=${displayBy}&field=${sortItem?.name || ""}&direction=${
          sortItem?.direction || ""
        }`
      )
      .then((response) => dispatch(actions.setPayrollList(response.data.payrolls)))
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      )
      .finally(() => setLoading(false));
  };

  const handleUploadFile = (e) => {
    const formData = new FormData();
    const files = e.currentTarget.files;

    for (let i = 0; i < files.length; i++) {
      formData.append("files[]", files[i]);
    }

    setFilesFormData(formData);
  };

  const handleUploadCancel = () => setFilesFormData();

  const handleRangeChange = (e) => {
    setDisplayBy(e.target.value);
    localStorage.setItem("runPayrollFilterBy", e.target.value);
    setSortItem();
  };

  const handleSortChange = (sortItemName, type) =>
    setSortItem({
      name: sortItemName,
      direction: type
    });

  const isSubscribedKeyExisting = Object.keys(userData).includes("is_subscribed");
  const isCompanySubscribed = userData.is_subscribed;
  const isPastDue = userData.is_past_due;
  const trialDaysDiff = Math.ceil(dayjs(userData.subscription_ends_at).diff(dayjs(), "hours") / 24);
  const isTrialEnded = userData.is_trial_expired || trialDaysDiff < 1;

  useEffect(() => {
    const runPayrollFilterBy = localStorage.getItem("runPayrollFilterBy");

    if (runPayrollFilterBy) {
      setDisplayBy(+runPayrollFilterBy);
    }
  }, []);

  return (
    <>
      <TitleInfo
        title="Payroll"
        subTitle="Upload your Charger Statement to streamline your payroll"
      />

      {!isUploading &&
        isSubscribedKeyExisting && !isPastDue &&
        (isCompanySubscribed || (!isCompanySubscribed && !isTrialEnded)) && (
          <RunPayrollDropzone handleUploadFile={handleUploadFile} />
        )}

      {isUploading && <RunPayrollUploadProcess handleUploadCancel={handleUploadCancel} />}

      {loading ? (
        <div className="run-payroll-height">
          <Loading />
        </div>
      ) : payrolls.length ? (
        <RunPayrollContent
          payrolls={payrolls}
          handleRangeChange={handleRangeChange}
          displayBy={displayBy}
          handleSortChange={handleSortChange}
          activeSortedItem={sortItem}
        />
      ) : (
        <EmptyState />
      )}
    </>
  );
};

export default RunPayroll;
