import "../../assets/css/modal.css";
import { useNavigate } from "react-router-dom";
import { Modal } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../assets/images/close-icon.svg";
import { ReactComponent as CloudConnection } from "../../assets/images/run-payroll-preview/cloud-connection.svg";

const NotSelectedCompanyModal = ({ open, handleClose }) => {
  const nav = useNavigate();

  const handleSubmit = () => nav("/app?section=manual");

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="confirm-modal not-selected-company-modal">
        <div className="confirm-modal-wrapper">
          <div className="confirm-modal-close-wrapper" onClick={handleClose}>
            <CloseIcon />
          </div>

          <div className="confirm-modal-icon-wrapper recalculate">
            <CloudConnection className="confirm-modal-icon company" />
          </div>

          <div className="not-selected-company-modal-text">
            Unable to perform Wage Calculation as no company has been selected. Please choose your
            payroll company to proceed with the calculation.
          </div>

          <div className="confirm-modal-btn-wrapper not-selected-company-modal-btn">
            <button className="confirm-modal-btn cancel company-btn" onClick={handleClose}>
              Cancel
            </button>
            <button className="confirm-modal-btn company company-btn" onClick={handleSubmit}>
              Connect company
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NotSelectedCompanyModal;
