import { formatMoney, formatNumber } from "./../../utils";
import { ReactComponent as Coin } from "../../assets/images/overview/coin.svg";
import { ReactComponent as MoneySend } from "../../assets/images/overview/money-send.svg";
import { ReactComponent as Location } from "../../assets/images/overview/location.svg";
import { ReactComponent as ArrowUp } from "../../assets/images/overview/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/overview/arrow-down.svg";
import { ReactComponent as PercentageCircle } from "../../assets/images/overview/percentage-circle.svg";
import Loading from "../common/Loading";

const TotalStatisticBlock = ({ totalData, isLoading }) => {
  const {
    profit_margin = 0,
    profit_margin_amount = 0,
    total_revenue,
    total_stops,
    last_week_total_revenue_percentage,
    last_week_total_stops_percentage,
    last_week_profit_margin_percentage
  } = totalData;

  return (
    <ul>
      <li>
        <div className="total-statistic-block-icon">
          <MoneySend />
        </div>
        <div className="total-statistic-block-text">
          <h5>Total Revenue</h5>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <h2 className="total-values">{formatMoney(total_revenue)}</h2>
              <div className="total-statistic-block-vs">
                {last_week_total_revenue_percentage > 0 ? (
                  <div className="total-statistic-block-vs-item green">
                    <ArrowUp />
                    {last_week_total_revenue_percentage
                      ? (Math.round(last_week_total_revenue_percentage * 10) / 10).toFixed(1)
                      : 0}
                    %
                  </div>
                ) : (
                  <div className="total-statistic-block-vs-item red">
                    <ArrowDown />
                    {last_week_total_revenue_percentage
                      ? (Math.round(last_week_total_revenue_percentage * 10) / 10).toFixed(1)
                      : 0}
                    %
                  </div>
                )}{" "}
                vs last week
              </div>
            </>
          )}
        </div>
      </li>

      <li>
        <div className="total-statistic-block-icon">
          <Location />
        </div>
        <div className="total-statistic-block-text">
          <h5>Total Stops</h5>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <h2 className="total-values">{formatNumber(total_stops)}</h2>
              <div className="total-statistic-block-vs">
                {last_week_total_stops_percentage > 0 ? (
                  <div className="total-statistic-block-vs-item green">
                    <ArrowUp />
                    {last_week_total_stops_percentage
                      ? (Math.round(last_week_total_stops_percentage * 10) / 10).toFixed(1)
                      : 0}
                    %
                  </div>
                ) : (
                  <div className="total-statistic-block-vs-item red">
                    <ArrowDown />
                    {last_week_total_stops_percentage
                      ? (Math.round(last_week_total_stops_percentage * 10) / 10).toFixed(1)
                      : 0}
                    %
                  </div>
                )}{" "}
                vs last week
              </div>
            </>
          )}
        </div>
      </li>

      <li>
        <div className="total-statistic-block-icon">
          <Coin />
        </div>
        <div className="total-statistic-block-text">
          <h5>Profit Margin</h5>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <h2 className="total-values">{formatMoney(profit_margin_amount)}</h2>
              <div className="total-statistic-block-vs">
                {last_week_profit_margin_percentage > 0 ? (
                  <div className="total-statistic-block-vs-item green">
                    <ArrowUp />
                    {last_week_profit_margin_percentage
                      ? (Math.round(last_week_profit_margin_percentage * 10) / 10).toFixed(1)
                      : 0}
                    %
                  </div>
                ) : (
                  <div className="total-statistic-block-vs-item red">
                    <ArrowDown />
                    {last_week_profit_margin_percentage
                      ? (Math.round(last_week_profit_margin_percentage * 10) / 10).toFixed(1)
                      : 0}
                    %
                  </div>
                )}{" "}
                vs last week
              </div>
            </>
          )}
        </div>
      </li>

      <li>
        <div className="total-statistic-block-icon">
          <PercentageCircle />
        </div>
        <div className="total-statistic-block-text">
          <h5>Profit Margin</h5>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <h2 className="total-values">{profit_margin}%</h2>
              <div className="total-statistic-block-vs">
                {last_week_profit_margin_percentage > 0 ? (
                  <div className="total-statistic-block-vs-item green">
                    <ArrowUp />
                    {last_week_profit_margin_percentage
                      ? (Math.round(last_week_profit_margin_percentage * 10) / 10).toFixed(1)
                      : 0}
                    %
                  </div>
                ) : (
                  <div className="total-statistic-block-vs-item red">
                    <ArrowDown />
                    {last_week_profit_margin_percentage
                      ? (Math.round(last_week_profit_margin_percentage * 10) / 10).toFixed(1)
                      : 0}
                    %
                  </div>
                )}{" "}
                vs last week
              </div>
            </>
          )}
        </div>
      </li>
    </ul>
  );
};

export default TotalStatisticBlock;
