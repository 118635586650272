import { CircularProgress } from '@mui/material';

const Loading = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <CircularProgress />
    </div>
  );
}

export default Loading;
