import { ReactComponent as Edit } from "../../../assets/images/settings/edit.svg";
import { ReactComponent as Delete } from "../../../assets/images/run-payroll/delete-filled.svg";
import { ReactComponent as CustomExpenses } from "../../../assets/images/settings/custom-expenses.svg";
import { formatMoney } from "./../../../utils";

const SettingsFixedExpensesContentItem = ({
  itemData,
  handleChangeItem,
  handleDelete,
  handleEditCustomOpen
}) => {
  const isPerEmployee = itemData.isPerEmployee || itemData.type === "per_employee";

  return (
    <div className="settings-expenses-content-item">
      <div className="settings-expenses-content-item-left">
        {itemData.id ? <CustomExpenses /> : itemData.icon}
        <div>{itemData.title || itemData.name}</div>
      </div>

      <div className="settings-expenses-content-item-right">
        <div className="settings-expenses-content-item-value">
          {itemData.isPercentage
            ? `${itemData.value} %`
            : formatMoney(itemData.value || itemData.cost, true)}{" "}
          {isPerEmployee && " /"} {isPerEmployee && <div>Employee</div>}
        </div>

        <div className="expenses-btns-wrapper">
          <button
            className="companies-btn edit"
            onClick={
              itemData.id
                ? handleEditCustomOpen.bind(null, itemData)
                : handleChangeItem.bind(null, itemData)
            }>
            <Edit />
          </button>
          {itemData.id && (
            <button className="companies-btn delete" onClick={handleDelete.bind(null, itemData)}>
              <Delete className="companies-delete" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingsFixedExpensesContentItem;
